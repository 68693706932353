.MultiSelect {
  outline: none;
  position: relative; }
  .MultiSelect .Header {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #949ca6;
    border-radius: 5px;
    color: #143154;
    overflow: hidden;
    max-width: 100%;
    padding: 0 15px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    outline: none; }
    .MultiSelect .Header--selected {
      box-shadow: inset 0 0 4px rgba(18, 152, 212, 0.3); }
    .MultiSelect .Header--disabled {
      border-color: #cfd4d9; }
    .MultiSelect .Header--focusedActive {
      border-color: #1298d4; }
    .MultiSelect .Header--focusedDisabled {
      border-color: #b3bbc2; }
    .MultiSelect .Header--expanded {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-color: #cfd4d9; }
    .MultiSelect .Header__value {
      box-sizing: border-box;
      display: flex;
      flex-grow: 1;
      height: 40px;
      line-height: 41px;
      color: #143154;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
      .MultiSelect .Header__value span {
        position: absolute;
        width: calc(100% - 45px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .MultiSelect .Header__value--resetable span {
      width: calc(100% - 65px); }
    .MultiSelect .Header__valuePlaceholder {
      color: #949ca6; }
    .MultiSelect .Header__valueCounter {
      color: #1298d4;
      margin-left: 5px; }
    .MultiSelect .Header__controls {
      box-sizing: border-box;
      display: flex;
      position: absolute;
      height: 100%;
      right: 0;
      padding: 0 12px;
      line-height: 40px;
      cursor: pointer; }
      .MultiSelect .Header__controls :not(:first-child) {
        margin-left: 10px; }
    .MultiSelect .Header__tags {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      overflow-x: auto;
      padding-right: 20px;
      margin-right: 40px;
      margin-bottom: -50px;
      padding-bottom: 50px; }
      .MultiSelect .Header__tags::-webkit-scrollbar {
        display: none; }
    .MultiSelect .Header__tag {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 29px;
      line-height: 29px;
      margin-right: 4px; }
      .MultiSelect .Header__tag__label {
        box-sizing: border-box;
        color: #fff;
        background-color: #1298d4;
        padding: 0 11px 0 10px;
        border-radius: 3px; }
      .MultiSelect .Header__tag__close {
        box-sizing: border-box;
        color: #143154;
        background-color: #cfd4d9;
        padding: 0 5px;
        position: relative;
        left: -3px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        cursor: pointer; }
        .MultiSelect .Header__tag__close:hover {
          color: #1298d4; }
    .MultiSelect .Header__arrow {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      border-bottom-color: #949ca6; }
      .MultiSelect .Header__arrow:hover {
        border-bottom-color: #1298d4; }
      .MultiSelect .Header__arrow .arrow, .MultiSelect .Header__arrow .Header__arrow--up, .MultiSelect .Header__arrow .Header__arrow--down {
        position: relative;
        border: 5.5px solid transparent;
        border-top-width: 0;
        border-bottom-color: inherit;
        height: 0;
        width: 0;
        transition: 0.4s; }
      .MultiSelect .Header__arrow--up {
        transform: rotate(360deg);
        top: -1px; }
      .MultiSelect .Header__arrow--down {
        transform: rotate(180deg);
        top: 1px; }
      .MultiSelect .Header__arrow--active {
        border-bottom-color: #1298d4 !important; }
      .MultiSelect .Header__arrow--disabled {
        border-bottom-color: #949ca6 !important; }
    .MultiSelect .Header__loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%; }
      .MultiSelect .Header__loading__icon {
        box-sizing: border-box;
        animation: loading_spin 800ms infinite linear;
        width: 16px;
        height: 16px;
        border: 3px dotted #cfd4d9;
        border-radius: 50%;
        border-right-color: #143154;
        display: inline-block;
        position: relative;
        vertical-align: middle; }

@keyframes loading_spin {
  to {
    transform: rotate(1turn); } }
    .MultiSelect .Header__reset {
      cursor: pointer;
      color: #949ca6; }
      .MultiSelect .Header__reset:hover {
        color: #1298d4; }
  .MultiSelect .DropDown {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #1298d4;
    border-top: none;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    max-height: 300px;
    z-index: 1;
    overflow: hidden; }
    .MultiSelect .DropDown--disabled {
      border-color: #cfd4d9; }
    .MultiSelect .DropDown__searchField {
      box-sizing: border-box;
      display: block;
      border: none;
      border-bottom: 1px solid #cfd4d9;
      height: 40px;
      width: 100%;
      max-width: 100%;
      line-height: 1;
      padding: 10px 15px;
      color: #143154;
      outline: none;
      font-family: inherit; }
      .MultiSelect .DropDown__searchField::placeholder {
        color: #949ca6;
        opacity: 1; }
      .MultiSelect .DropDown__searchField--selected {
        box-shadow: inset 0 0 3px rgba(18, 152, 212, 0.3);
        color: #143154; }
    .MultiSelect .DropDown__selectAll .Option__label {
      color: #143154; }
  .MultiSelect .OptionList {
    margin: 0;
    padding-left: 0;
    max-height: 259px;
    overflow-y: auto;
    scroll-behavior: smooth; }
    .MultiSelect .OptionList__item {
      list-style: none; }
  .MultiSelect .Option {
    box-sizing: border-box;
    display: block;
    padding: 0 15px;
    line-height: 38px;
    background-color: #fff;
    color: #143154;
    cursor: pointer;
    outline: none; }
    .MultiSelect .Option--focus {
      background-color: #f3f3f3;
      outline: 0; }
    .MultiSelect .Option--hover {
      background-color: #ebf5ff;
      outline: 0; }
    .MultiSelect .Option__label {
      display: inline-block;
      vertical-align: middle;
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
      cursor: default;
      padding: 2px 5px; }
    .MultiSelect .Option__renderer {
      display: flex;
      justify-content: space-between;
      align-items: center; }
